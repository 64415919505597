import OnboardCompanyModal from '@invisible/companies/onboard-company-modal'
import {
  fromGlobalId,
  ICompanyFilterSet,
  toGlobalId,
  useCompaniesTableDataQuery,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useMutation as useTrpcMutation, useQuery } from '@invisible/trpc/client'
import { CopyButton } from '@invisible/ui/copy-button'
import { format } from '@invisible/ui/helpers'
import { adminTheme, chipVariantIcons } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  DataGridPro,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowsProp,
} from '@mui/x-data-grid-pro'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo, useState } from 'react'

interface CompaniesTableProps {
  pageSize?: 10 | 25 | 50 | 75 | 100
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const CompaniesTableGQL: FC<CompaniesTableProps> = ({ pageSize = 25 }) => {
  const { addToast } = useToasts()
  const router = useRouter()

  const [modalOpen, setModalOpen] = useState(false)
  const [managers, setManagers] = useState<Record<string, string[]>>({})
  const [rowCount, setRowCount] = useState(0)

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize })
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'status',
        operator: 'is',
        value: 'active',
      },
    ],
  })

  const { data: allCompaniesReportData, isLoading: allCompaniesReportDataIsLoading } = useQuery([
    'report.companiesOverview',
  ])
  const { mutateAsync: updateCompanyManagers } = useTrpcMutation(
    'company.updateCompanyManagers',
    {}
  )

  const onFilterChange = (model: GridFilterModel) => {
    setFilterModel(model)
  }

  const adjustedFilters: ICompanyFilterSet = filterModel.items.reduce(
    (acc: ICompanyFilterSet, item: GridFilterItem) => {
      if (item.field === 'name' && item.value) {
        acc.name = {
          iContains: item.value,
        }
      }
      if (item.field === 'status' && item.operator === 'is' && item.value) {
        acc.status = {
          exact: item.value.toUpperCase(),
        }
      }
      if (filterModel.quickFilterValues?.length) {
        acc.name = {
          iContains: filterModel.quickFilterValues.join(' '),
        }
      }
      return acc
    },
    {}
  )

  const { data: companiesQueryResult, isLoading } = useCompaniesTableDataQuery({
    filters: adjustedFilters,
    first: paginationModel.pageSize,
    after:
      paginationModel.page === 0
        ? null
        : toGlobalId('arrayconnection', `${paginationModel.page * paginationModel.pageSize - 1}`),
  })
  const companiesData = companiesQueryResult?.companies?.edges.map((edge) => edge.node)
  const totalCount = companiesQueryResult?.companies?.totalCount

  useEffect(() => {
    if (totalCount != null) {
      setRowCount(totalCount)
    }
  }, [totalCount])

  const tableData: GridRowsProp = useMemo(
    () =>
      (companiesData || []).map((company) => ({
        id: fromGlobalId(company.id),
        name: company.name,
        createdAt: company.createdAt,
        status: company.status,
        ownerName: company.owner?.preferredName ?? company.owner?.name ?? '',
        ownerEmail: company.owner?.email ?? '',
        stripeCustomerId: company.stripeCustomerId ?? '',
        xeroContactId: company.xeroContactId ?? '',
        operationsManager: company.companyToOperationManagers
          ? company.companyToOperationManagers.map((opManager) => opManager.user?.name).join(', ')
          : '',
        primaryProcessOperationsManager: company.processOperationsManagers
          ? company.processOperationsManagers
              .filter((pom) => pom.role === 'primary')
              .map((pom) => pom.user.name || '')
              .join(', ')
          : '',
        secondaryProcessOperationsManager: company.processOperationsManagers
          ? company.processOperationsManagers
              .filter((pom) => pom.role === 'secondary')
              .map((pom) => pom.user.name || '')
              .join(', ')
          : '',
      })),
    [companiesData]
  )

  const tableColumns: GridColDef[] = [
    {
      field: 'id',
      display: 'flex',
      headerName: 'ID',
      renderCell: ({ row: { id } }) => (
        <CopyButton message='ID successfully copied to clipboard' text={id} />
      ),
      width: 50,
      filterable: false,
      sortable: false,
    },
    {
      field: 'name',
      display: 'flex',
      headerName: 'Name',
      align: 'left',
      width: 200,
      renderCell: ({ row: { id, name } }) => (
        <Button variant='text' onClick={() => router.push(`/companies/${id}`)}>
          {name}
        </Button>
      ),
      sortable: false,
      filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
      field: 'status',
      display: 'flex',
      headerName: 'Status',
      align: 'left',
      renderCell: ({ row: { status } }) => (
        <Chip variant={status} icon={chipVariantIcons[status].icon} label={status} />
      ),
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        'prospect',
        'piloting',
        'onboarding',
        'active',
        'demo',
        'inactive',
        'offboarding',
        'churned',
      ],
      sortable: false,
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    },
    {
      field: 'ownerName',
      display: 'flex',
      headerName: 'Owner Name',
      align: 'left',
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: 'ownerEmail',
      display: 'flex',
      headerName: 'Owner Email',
      align: 'left',
      width: 250,
      renderCell: ({ row: { ownerEmail } }) =>
        ownerEmail ? (
          <>
            <CopyButton message='Email successfully copied to clipboard' text={ownerEmail} />
            <Typography variant='body2' noWrap>
              {ownerEmail}
            </Typography>
          </>
        ) : (
          '-'
        ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'lifetimeRevenue',
      display: 'flex',
      headerName: 'Lifetime Revenue',
      align: 'left',
      width: 200,
      type: 'number',
      headerAlign: 'left',
      valueGetter: (_, { id }) => {
        const companyReportData = allCompaniesReportData?.find(
          (company) => company?.manticore_company_id === id
        )
        return companyReportData?.lifetime_usage_revenue || 0
      },
      renderCell: ({ row: { id, createdAt } }) => {
        const companyReportData = allCompaniesReportData?.find(
          (company) => company?.manticore_company_id === id
        )

        const lifetimeRevenue = companyReportData?.lifetime_usage_revenue

        return lifetimeRevenue ? (
          <Stack justifyContent='space-between'>
            <Typography variant='body2'>{format.formatCurrency(lifetimeRevenue)}</Typography>
            {/* The createdAt datum here is the date when
            the company was added to Ultron */}
            <Chip label={createdAt ? `since ${new Date(createdAt).toDateString()}` : ''} />
          </Stack>
        ) : (
          <Typography variant='body2'>$0.00</Typography>
        )
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'stripeCustomerId',
      display: 'flex',
      headerName: 'Stripe ID',
      align: 'left',
      sortable: false,
      filterable: false,
      type: 'boolean',
      headerAlign: 'left',
      valueGetter: (_, { stripeCustomerId }) => stripeCustomerId != null,
      renderCell: ({ row: { stripeCustomerId } }) =>
        stripeCustomerId ? (
          <CopyButton
            message='Stripe Customer ID successfully copied to clipboard'
            text={stripeCustomerId}
          />
        ) : (
          '-'
        ),
      width: 100,
    },
    {
      field: 'xeroContactId',
      display: 'flex',
      headerName: 'Xero ID',
      align: 'left',
      sortable: false,
      filterable: false,
      type: 'boolean',
      headerAlign: 'left',
      valueGetter: (_, { xeroContactId }) => xeroContactId != null,
      renderCell: ({ row: { xeroContactId } }) =>
        xeroContactId ? (
          <CopyButton
            message='Xero Contact ID successfully copied to clipboard'
            text={xeroContactId}
          />
        ) : (
          '-'
        ),
      width: 100,
    },
    {
      field: 'operationsManager',
      display: 'flex',
      headerName: 'Operations Manager',
      width: 220,
      editable: true,
      type: 'singleSelect',
      filterable: false,
      sortable: false,
    },
    {
      field: 'primaryProcessOperationsManager',
      display: 'flex',
      headerName: 'Primary Process Operations Manager',
      width: 220,
      editable: false,
      renderCell: ({ row: { primaryProcessOperationsManager } }) => (
        <Typography variant='body2' noWrap>
          {primaryProcessOperationsManager}
        </Typography>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'secondaryProcessOperationsManager',
      display: 'flex',
      headerName: 'Secondary Process Operations Managers',
      width: 220,
      editable: false,
      renderCell: ({ row: { secondaryProcessOperationsManager } }) => (
        <Typography variant='body2' noWrap>
          {secondaryProcessOperationsManager}
        </Typography>
      ),
      filterable: false,
      sortable: false,
    },
  ]

  const initialState: GridInitialState = {
    pagination: { paginationModel: { pageSize } },
    pinnedColumns: { left: ['id', 'name'] },
  }

  return (
    <ThemeProvider theme={adminTheme}>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack spacing={2}>
            <Typography variant='h6' fontWeight='bold'>
              Companies - GQL Variant
            </Typography>
            <Typography variant='body2'>Who do we work for?</Typography>
          </Stack>
          <Button variant='contained' size='medium' onClick={() => setModalOpen(true)}>
            Onboard Company
          </Button>
        </Stack>

        <Box height='calc(100vh - 300px)' overflow='auto' width='100%'>
          <DataGridPro
            sx={{ overflow: 'auto' }}
            initialState={initialState}
            checkboxSelection={false}
            rows={tableData ?? []}
            rowCount={rowCount}
            columns={tableColumns}
            pageSizeOptions={[10, 25, 50, 75, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel: GridPaginationModel) => {
              setPaginationModel(newModel)
            }}
            paginationMode='server'
            sortingMode='server'
            filterMode='server'
            filterModel={filterModel}
            onFilterModelChange={onFilterChange}
            processRowUpdate={(updatedRow) => {
              const operationsManagerIds: string[] = updatedRow.operationsManager?.map(
                (item: { id: string; name: string }) => item.id
              )
              const operationsManagerName: string[] = updatedRow.operationsManager?.map(
                (item: { id: string; name: string }) => item.name
              )

              setManagers({ ...managers, [updatedRow.id]: operationsManagerName })
              updateCompanyManagers({
                id: updatedRow.id,
                operationsManagerIds,
              })
              return updatedRow
            }}
            onProcessRowUpdateError={(error) => {
              addToast('Failed to update company', { appearance: 'error' })
              logger.error('Failed to update company managers', { error })
            }}
            loading={isLoading || allCompaniesReportDataIsLoading}
            slots={{
              ...adminTheme.components?.MuiDataGrid?.defaultProps?.slots,
            }}
          />
        </Box>
        <OnboardCompanyModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Stack>
    </ThemeProvider>
  )
}
